<template>
  <div class="overflow-y-scroll text-xs viewer-body">
    <div>
      <div class="pt-3 text-xs bg-gray-700 border-b-2 border-gray-800 tabs sub-tabs">
        <template v-for="tab in tabs">
          <a
            href="#"
            class="tab"
            :class="{ 'font-bold bg-gray-800 rounded-t-sm' : active === tab.type }"
            @click.prevent="active = tab.type"
          >
            <span class="text-gray-100">{{ tab.label }}</span>
          </a>
        </template>
      </div>

      <div class="">
        <component
          :is="component"
          :gui="gui"
          @settings-changed="$emit('settings-changed', $event)"
        />
      </div>
    </div>
  </div>
</template>

<script>
const WorldPanel = () => import('./panels/WorldPanel')
const BookmarkPanel = () => import('./panels/BookmarkPanel')
const SettingsPanel = () => import('./panels/SettingsPanel')
const MappingsPanel = () => import('./panels/MappingsPanel')

export default {
  name: 'ViewerBody',

  components: {
    WorldPanel,
    BookmarkPanel,
    MappingsPanel,
    SettingsPanel
  },

  props: {
    gui: {
      type: Object,
      required: true
    }
  },

  data () {
    return {
      active: null,

      tabs: [
        { type: 'world', label: 'World' },
        { type: 'cameras', label: 'Bookmarks' }
        // { type: 'mappings', label: 'Mapping Groups' },
        // { type: 'settings', label: 'Settings' }
      ]
    }
  },

  computed: {
    component () {
      switch (this.active) {
        case 'cameras':
          return 'BookmarkPanel'
        case 'mappings':
          return 'MappingsPanel'
        case 'settings':
          return 'SettingsPanel'
        case 'world':
          return 'WorldPanel'
        default:
          return null
      }
    }
  },

  mounted () {
  //  this.active = 'world'
  }
}

</script>
